export const getLandingPageUrlFromRoute = ({ continent, country, region, subregion }: any): string => {
    let url = `${continent}/${country}`
    if (region) {
        url += `/${region}`
    }
    if (subregion) {
        url += `/${subregion}`
    }
    return url
}

export const generateSearchParams = (currentParams: URLSearchParams, key: string, value: any) => {
    currentParams.set(key, value)
    return currentParams
}
