import { useCallback, useState } from 'react'

import { searchClient } from '../../../../../clients'
import { useRequestUserConfigContext } from '../../../../../hooks/request'
import RegionsAutocompleteSuggestionTransport from '../../../../../transports/regions-autocomplete-suggestion-transport'
import { RequestUserConfig } from '../../../../../common/types'
import { useAsyncCallback } from '../../../../../hooks/async'
import AnalyticsService from '../../../../../services/analytics-service'
import { commonPageEvents } from '../../../../../services/analytics-service/events'

const EMPTY_ARRAY: any[] = []

const _getRegionsAutocompleteSuggestions = async (user: RequestUserConfig, locationInputValue: string) => {
    const response = await searchClient.getRegionsAutocompleteSuggestions({
        user,
        params: { query: locationInputValue },
    })

    return response.data
}

export const useRegionsAutocompleteSuggestions = () => {
    const user = useRequestUserConfigContext()

    const {
        data: regionsAutocompleteSuggestions,
        isLoading,
        error,
        saveAsync,
        callback,
    } = useAsyncCallback(_getRegionsAutocompleteSuggestions, EMPTY_ARRAY as RegionsAutocompleteSuggestionTransport[])

    const getRegionsAutocompleteSuggestions = useCallback(
        (locationInputValue: string) => callback(user, locationInputValue),
        [user, callback],
    )

    return {
        regionsAutocompleteSuggestions: regionsAutocompleteSuggestions as RegionsAutocompleteSuggestionTransport[],
        isLoading,
        error,
        getRegionsAutocompleteSuggestions,
        setRegionsAutocompleteSuggestions: saveAsync,
    }
}

export const useTracking = () => {
    const [eventFired, setEventFired] = useState(false)

    const _handleKeyUp = useCallback(() => {
        if (!eventFired) {
            AnalyticsService.trackEvent({
                event: commonPageEvents.keyBoardEvents.KEY_UP_EVENT,
                eventValue: 'form_query',
            })
            setEventFired(true)
        }
    }, [eventFired])

    return {
        onKeyUp: _handleKeyUp,
    }
}
