import { BaseTransport } from '../transports'
import ListingSectionTransport from '../transports/common/listing-section-transport'
import { RequestUserConfig } from '../common/types'
import { NetworkService } from '../services'

class ListingClient {
    public getTopRatedListings({
        user,
        params,
    }: {
        user: RequestUserConfig
        params: any
    }): Promise<BaseTransport<ListingSectionTransport>> {
        const headers = {
            ...(user.session?.id && { 'x-session-id': user.session.id }),
        }

        return NetworkService.UNSAFE_post({
            url: '/api/listings/v1/top-rated-listings',
            headers,
            body: params,
        })
    }

    public getTopOfferListings({
        user,
        params,
    }: {
        user: RequestUserConfig
        params: any
    }): Promise<BaseTransport<ListingSectionTransport>> {
        const headers = {
            ...(user.session?.id && { 'x-session-id': user.session.id }),
        }

        return NetworkService.UNSAFE_post({
            url: '/api/listings/v1/top-offer-listings',
            headers,
            body: params,
        })
    }

    public getPopularFilterListings({
        user,
        params,
    }: {
        user: RequestUserConfig
        params: any
    }): Promise<BaseTransport<ListingSectionTransport>> {
        const headers = {
            ...(user.session?.id && { 'x-session-id': user.session.id }),
        }

        return NetworkService.UNSAFE_post({
            url: '/api/listings/v1/popular-filter-listings',
            headers,
            body: params,
        })
    }

    public addToWishlist(user: RequestUserConfig, id: string): Promise<BaseTransport<void>> {
        const headers = {
            ...(user.session?.id && { 'x-session-id': user.session.id }),
        }

        return NetworkService.put({
            url: `/api/listings/v1/${id}/favorites`,
            headers,
        })
    }

    public removeFromWishlist(user: RequestUserConfig, id: string): Promise<BaseTransport<void>> {
        const headers = {
            ...(user.session?.id && { 'x-session-id': user.session.id }),
        }

        return NetworkService.delete({
            url: `/api/listings/v1/${id}/favorites`,
            headers,
        })
    }
}

export default new ListingClient()
