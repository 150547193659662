import React, { FC, memo } from 'react'
import { AnyStyledComponent } from 'styled-components'

import { FeatureIconImage } from './styles'
import ACIcon from '../../../assets/icons/filter-icons/ac.svg'
import DishwasherIcon from '../../../assets/icons/filter-icons/dishwasher.svg'
import PetsIcon from '../../../assets/icons/feature-icons/pets.svg'
import PetsProhibitedIcon from '../../../assets/icons/feature-icons/pets-prohibited.svg'
import PoolIcon from '../../../assets/icons/filter-icons/pool.svg'
import SmokingIcon from '../../../assets/icons/feature-icons/smoking.svg'
import SmokingProhibitedIcon from '../../../assets/icons/feature-icons/smoking-prohibited.svg'
import TelevisionIcon from '../../../assets/icons/filter-icons/television.svg'
import WashingMachineIcon from '../../../assets/icons/filter-icons/washing-machine.svg'
import WifiIcon from '../../../assets/icons/feature-icons/wifi.svg'

interface Props {
    featureKey: string
}

export const getFeatureIconComponent = (featureKey: string) => {
    switch (featureKey) {
        case 'wifi':
            return WifiIcon
        case 'pets':
            return PetsIcon
        case 'pets-prohibited':
            return PetsProhibitedIcon
        case 'pets-unspecified':
            return null
        case 'smoking':
            return SmokingIcon
        case 'smoking-prohibited':
            return SmokingProhibitedIcon
        case 'smoking-unspecified':
            return null
        case 'washing-machine':
            return WashingMachineIcon
        case 'television':
            return TelevisionIcon
        case 'pool':
            return PoolIcon
        case 'dishwasher':
            return DishwasherIcon
        default:
            return ACIcon
    }
}

const FeatureIcon: FC<Props> = ({ featureKey }) => {
    const featureIconComponent = getFeatureIconComponent(featureKey)
    return featureIconComponent ? <FeatureIconImage as={featureIconComponent as AnyStyledComponent} /> : null
}

export default memo(FeatureIcon)
