import { useCallback, useState } from 'react'
import { parse } from 'query-string'

import ListingSectionTransport from '../transports/common/listing-section-transport'
import { fastlaneClient, listingClient, searchClient } from '../clients'
import { useAsyncCallback } from './async'
import { ListingObjectsTransport, NormalizedObjects } from '../transports/search-result-listing-page-transport'
import ListingsPricesTransport, { ListingsPricesParamsTransport } from '../transports/listings-prices-transport'
import SearchListingsTransport, {
    SearchListingsParamsTransport,
    SearchListingsRequestBodyTransport,
} from '../transports/search-listings-transport'
import { decodeFromBase64, encodeToBase64 } from '../utils/encoding-utils'
import { generateSearchParams } from '../utils/route-utils'
import { RequestUserConfig } from '../common/types'
import ListingLocationTransport, { ListingsLocationsParamsTransport } from '../transports/listing-location-transport'
import { useRequestUserConfigContext } from './request'

export interface SearchResultsListingsState extends Omit<SearchListingsTransport, 'objects'> {
    objects: NormalizedObjects
}

export const useTopRatedListingsSection = (topRatedListingsSection: ListingSectionTransport) => {
    const [section, setSection] = useState(topRatedListingsSection)
    const [isLoading, setIsLoading] = useState(false)
    const { nextPage, objects } = section
    const user = useRequestUserConfigContext()

    const getTopRatedListings = useCallback(async () => {
        if (nextPage && !isLoading) {
            const [, searchParams] = nextPage.split(/\?(.+)/)
            if (searchParams) {
                const params = parse(searchParams)
                try {
                    setIsLoading(true)
                    const response = await listingClient.getTopRatedListings({ user, params })
                    setSection(section => ({
                        ...section,
                        nextPage: response.data.nextPage,
                        objects: [...objects, ...response.data.objects],
                    }))
                } catch (error) {
                    console.error({ error })
                } finally {
                    setIsLoading(false)
                }
            }
        }
    }, [user, nextPage, isLoading, objects])

    return {
        section,
        isLoading,
        getTopRatedListings,
    }
}

const _getSearchResultsPrices = async (user: RequestUserConfig, params: ListingsPricesParamsTransport) => {
    const response = await searchClient.getListingsPrices({ user, params })
    return response.data
}

export const useSearchResultsPrices = () => {
    const user = useRequestUserConfigContext()

    const {
        data: searchResultsPrices,
        isLoading,
        error,
        callback,
    } = useAsyncCallback(_getSearchResultsPrices, {} as ListingsPricesTransport)

    const getSearchResultsPrices = useCallback(
        async (params: ListingsPricesParamsTransport) => {
            if (!isLoading) {
                return callback(user, params)
            }
            return searchResultsPrices as ListingsPricesTransport
        },
        [isLoading, searchResultsPrices, callback, user],
    )

    return {
        searchResultsPrices: searchResultsPrices as ListingsPricesTransport,
        isLoading,
        error,
        getSearchResultsPrices,
    }
}

const _getSearchResultsLocations = async (user: RequestUserConfig, params: ListingsLocationsParamsTransport) => {
    const response = await searchClient.getListingsLocations({ user, params })
    return response.data
}

export const useSearchResultsLocations = () => {
    const user = useRequestUserConfigContext()

    const {
        data: searchResultsLocations,
        isLoading,
        error,
        callback,
    } = useAsyncCallback(_getSearchResultsLocations, [] as ListingLocationTransport[])

    const getSearchResultsLocations = useCallback(
        async (
            filter: string, // this is actual path and not encoded
            topLeftLat: number,
            topLeftLng: number,
            bottomRightLat: number,
            bottomRightLng: number,
            limit?: number, // optional: if not provided 800 will be the limit of locations
        ) => {
            if (!isLoading) {
                const generatedURL = new URL(filter, window.location.origin ?? 'https://www.traum-ferienwohnungen.de')

                generateSearchParams(
                    generatedURL.searchParams,
                    'coordinates',
                    [topLeftLat, topLeftLng, bottomRightLat, bottomRightLng].join('+'),
                )

                const params: ListingsLocationsParamsTransport = {
                    filter: encodeToBase64(generatedURL.pathname + generatedURL.search),
                }

                if (limit) {
                    params.limit = limit
                }

                return callback(user, params)
            }
            return searchResultsLocations as ListingLocationTransport[]
        },
        [isLoading, searchResultsLocations, callback, user],
    )

    return {
        searchResultsLocations: searchResultsLocations as ListingLocationTransport[],
        isLoading,
        error,
        getSearchResultsLocations,
    }
}

const _getSearchResultsListingsObjects = async (
    user: RequestUserConfig,
    params: SearchListingsParamsTransport,
    body?: SearchListingsRequestBodyTransport,
) => {
    const response = await searchClient.getListings({ user, params, body })
    return response.data
}

const _modifyObjects = (normalizedObjects: NormalizedObjects, objects: ListingObjectsTransport[], append: boolean) => {
    if (!append) {
        normalizedObjects = {
            byId: {},
            allIds: [],
            isPriceMutated: false,
        }
    }

    for (const object of objects) {
        const id = object.id

        if (!normalizedObjects['byId'][id]) {
            normalizedObjects['byId'][id] = object
            normalizedObjects['allIds'].push(id)
        } else {
            // For append
            // If object was already present only Id is pushed into 'allIds' for looping on frontend
            normalizedObjects['allIds'].push(id)
        }
    }

    return normalizedObjects
}

export const useSearchResultsListingsObjects = () => {
    const user = useRequestUserConfigContext()

    const {
        data: searchResultsListingsObjects,
        isLoading,
        error,
        callback,
    } = useAsyncCallback(_getSearchResultsListingsObjects, {} as SearchListingsTransport)

    const [normalizedObjects, setNormalizedObjects] = useState<SearchResultsListingsState>({
        ...searchResultsListingsObjects,
        objects: {
            byId: {},
            allIds: [],
            isPriceMutated: false,
        },
    })

    const getSearchResultsListingsObjects = useCallback(
        async (params: SearchListingsParamsTransport, append: boolean, coordinates?: number[]) => {
            if (!isLoading) {
                if (params && params.filter && coordinates) {
                    const generatedURL = new URL(
                        decodeFromBase64(params.filter),
                        window.location.origin ?? 'https://www.traum-ferienwohnungen.de',
                    )

                    generateSearchParams(generatedURL.searchParams, 'coordinates', coordinates.join('+'))

                    params.filter = encodeToBase64(generatedURL.pathname + generatedURL.search)
                }

                const response = await callback(user, params)

                const normalizedResponse: SearchResultsListingsState = {
                    ...response,
                    objects: _modifyObjects(normalizedObjects.objects, response.objects, append),
                }

                setNormalizedObjects(normalizedResponse)

                return normalizedResponse
            }
            return normalizedObjects
        },
        [callback, isLoading, normalizedObjects, user],
    )

    const updateSearchResultsListingsObjectPrice = useCallback((priceResponse: ListingsPricesTransport) => {
        let result

        setNormalizedObjects(previousResponse => {
            const resultingObjects = { ...previousResponse.objects }

            for (const objectId in priceResponse) {
                if (resultingObjects.byId[objectId] && priceResponse[objectId]) {
                    // If price(obj) cannot be converted to number
                    // or price(obj) is different from price(arg)
                    // then only update price
                    if (
                        Number.isNaN(+resultingObjects.byId[objectId].price) ||
                        +resultingObjects.byId[objectId].price !== +priceResponse[objectId].price
                    ) {
                        Object.assign(resultingObjects.byId[objectId], priceResponse[objectId])
                    } else {
                        resultingObjects.byId[objectId].unit = null
                        resultingObjects.byId[objectId].isPriceNumeric = false
                        resultingObjects.byId[objectId].persons = null
                        resultingObjects.byId[objectId].notFormattedPrice = null
                    }
                }
            }

            resultingObjects.isPriceMutated = true

            result = {
                ...previousResponse,
                objects: resultingObjects,
            }

            // updating listing state
            return result
        })

        // returning result from this call
        return result
    }, [])

    return {
        searchResultsListingsObjects: normalizedObjects,
        isLoading,
        error,
        getSearchResultsListingsObjects,
        updateSearchResultsListingsObjectPrice,
    }
}

export const useTopOffersSection = (topOffersSection: ListingSectionTransport) => {
    const user = useRequestUserConfigContext()
    const [section, setSection] = useState(topOffersSection)
    const [isLoading, setIsLoading] = useState(false)
    const { nextPage, objects } = section

    const getTopOfferListings = useCallback(async () => {
        if (nextPage && !isLoading) {
            const [, searchParams] = nextPage.split(/\?(.+)/)
            if (searchParams) {
                const params = parse(searchParams)
                try {
                    setIsLoading(true)
                    const response = await listingClient.getTopOfferListings({ user, params })
                    setSection(section => ({
                        ...section,
                        nextPage: response.data.nextPage,
                        objects: [...objects, ...response.data.objects],
                    }))
                } catch (error) {
                    console.error({ error })
                } finally {
                    setIsLoading(false)
                }
            }
        }
    }, [user, nextPage, isLoading, objects])

    return {
        section,
        isLoading,
        getTopOfferListings,
    }
}

export const usePopularFilterSection = (popularFiltersSection: ListingSectionTransport) => {
    const user = useRequestUserConfigContext()
    const [section, setSection] = useState(popularFiltersSection)
    const [isLoading, setIsLoading] = useState(false)
    const { nextPage, objects } = section

    const getPopularFilterListings = useCallback(async () => {
        if (nextPage && !isLoading) {
            const [, searchParams] = nextPage.split(/\?(.+)/)
            if (searchParams) {
                const params = parse(searchParams)
                try {
                    setIsLoading(true)
                    const response = await listingClient.getPopularFilterListings({ user, params })
                    setSection(section => ({
                        ...section,
                        nextPage: response.data.nextPage,
                        objects: [...objects, ...response.data.objects],
                    }))
                } catch (error) {
                    console.error({ error })
                } finally {
                    setIsLoading(false)
                }
            }
        }
    }, [user, nextPage, isLoading, objects])

    return {
        section,
        isLoading,
        getPopularFilterListings,
    }
}

export const useWishList = (regionId: number, initialIsWishListed: boolean) => {
    const [isWishListed, setIsWishListed] = useState(initialIsWishListed)
    const [isLoading, setIsLoading] = useState(false)

    const handleWishListToggle = useCallback(async () => {
        if (!isLoading) {
            setIsLoading(true)
            try {
                if (!isWishListed) {
                    const response = await fastlaneClient.addToWishlist({ regionId })
                    // TODO: Test this out.
                    if (response.status === 'OK') {
                        setIsWishListed(true)
                    }
                } else {
                    const response = await fastlaneClient.removeFromWishlist({ regionId })
                    // TODO: Test this out.
                    if (response.status === 'OK') {
                        setIsWishListed(false)
                    }
                }
            } catch {
            } finally {
                setIsLoading(false)
            }
        }
    }, [isWishListed, isLoading, regionId])

    return {
        isWishListed,
        isLoading,
        handleWishListToggle,
    }
}
