import { NetworkService } from '../services'
import { BaseTransport } from '../transports'
import FiltersConfigurationTransport from '../transports/filters-configuration-transport'
import { RequestUserConfig } from '../common/types'
import RegionsAutocompleteSuggestionTransport, {
    RegionsAutocompleteSuggestionParamsTransport,
} from '../transports/regions-autocomplete-suggestion-transport'
import SearchUrlTransport, { SearchUrlRequestBodyTransport } from '../transports/search-url-transport'
import ListingLocationTransport, { ListingsLocationsParamsTransport } from '../transports/listing-location-transport'
import ListingsPricesTransport, { ListingsPricesParamsTransport } from '../transports/listings-prices-transport'
import SearchListingsTransport, {
    SearchListingsParamsTransport,
    SearchListingsRequestBodyTransport,
} from '../transports/search-listings-transport'

class SearchClient {
    public getFiltersConfiguration({
        user,
        filter,
        clear,
    }: {
        user: RequestUserConfig
        filter: string
        clear?: string
    }): Promise<BaseTransport<FiltersConfigurationTransport>> {
        const headers = {
            ...(user.session?.id && { 'x-session-id': user.session.id }),
        }

        return NetworkService.get({
            url: '/api/search/v1/filters',
            headers,
            params: { filter, clear },
        })
    }

    public getRegionsAutocompleteSuggestions({
        user,
        params,
    }: {
        user: RequestUserConfig
        params: RegionsAutocompleteSuggestionParamsTransport
    }): Promise<BaseTransport<RegionsAutocompleteSuggestionTransport[]>> {
        const headers = {
            'x-language': user.language,
            'x-domain': user.domain,
            ...(user.session?.id && { 'x-session-id': user.session.id }),
        }

        return NetworkService.get({
            // url: '/v3/3cdb2f91-81c1-4c24-8ba4-3bd7f42d5724',
            url: '/api/search/v1/regions-autocomplete-suggestions',
            headers,
            params,
        })
    }

    public getSearchUrl({
        user,
        filter,
        params,
    }: {
        user: RequestUserConfig
        filter?: string
        params: SearchUrlRequestBodyTransport
    }): Promise<BaseTransport<SearchUrlTransport>> {
        const headers = {
            'x-language': user.language,
            'x-domain': user.domain,
            ...(user.session?.id && { 'x-session-id': user.session.id }),
        }

        return NetworkService.post({
            url: '/api/search/v1/search-url',
            headers,
            params: { filter },
            body: params,
        })
    }

    public getListings({
        user,
        params,
        body,
    }: {
        user: RequestUserConfig
        params: SearchListingsParamsTransport
        body?: SearchListingsRequestBodyTransport
    }): Promise<BaseTransport<SearchListingsTransport>> {
        const headers = {
            'x-language': user.language,
            'x-domain': user.domain,
            ...(user.session?.id && { 'x-session-id': user.session.id }),
        }

        return NetworkService.post({
            url: '/api/search/v1/listings',
            headers,
            params,
            body,
        })
    }

    public getListingsLocations({
        user,
        params,
    }: {
        user: RequestUserConfig
        params: ListingsLocationsParamsTransport
    }): Promise<BaseTransport<ListingLocationTransport[]>> {
        const headers = {
            'x-language': user.language,
            'x-domain': user.domain,
            ...(user.session?.id && { 'x-session-id': user.session.id }),
        }

        return NetworkService.get({
            url: '/api/search/v1/listings/locations',
            headers,
            params,
        })
    }

    public getListingsPrices({
        user,
        params,
    }: {
        user: RequestUserConfig
        params: ListingsPricesParamsTransport
    }): Promise<BaseTransport<ListingsPricesTransport>> {
        const headers = {
            'x-language': user.language,
            'x-domain': user.domain,
            ...(user.session?.id && { 'x-session-id': user.session.id }),
        }

        return NetworkService.get({
            url: '/api/search/v1/prices',
            headers,
            params,
        })
    }
}

export default new SearchClient()
