import { useCallback, useMemo } from 'react'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'

import { CallbackFunction } from '../common/types'

// TODO: Extend this such that type of callback can also be a non-promise. Only types need to change.
//  Logic is able to handle.
export const useDebounce = <R = any>(callback: CallbackFunction<Promise<R>>, time: number) => {
    const debounced = useMemo(
        () =>
            debounce(async (resolve: (value: R | PromiseLike<R>) => void, reject: (reason?: any) => void, ...args) => {
                try {
                    const result = await callback(...args)
                    resolve(result)
                } catch (error) {
                    reject(error)
                }
            }, time),
        [callback, time],
    )

    return useCallback(
        (...args) => new Promise<R>((resolve, reject) => debounced(resolve, reject, ...args)),
        [debounced],
    )
}

// TODO: Extend this such that type of callback can also be a non-promise. Only types need to change.
//  Logic is able to handle.
export const useThrottle = <R = any>(callback: CallbackFunction<Promise<R>>, time: number) => {
    const throttled = useMemo(
        () =>
            throttle(async (resolve: (value: R | PromiseLike<R>) => void, reject: (reason?: any) => void, ...args) => {
                try {
                    const result = await callback(...args)
                    resolve(result)
                } catch (error) {
                    reject(error)
                }
            }, time),
        [callback, time],
    )

    return useCallback(
        (...args) => new Promise<R>((resolve, reject) => throttled(resolve, reject, ...args)),
        [throttled],
    )
}
