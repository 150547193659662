import React, { FC, memo } from 'react'

import SearchbarLocationPicker from '../location-picker'
import { useRegionsAutocompleteSuggestions, useTracking } from './hooks'
import { useDebounce } from '../../../../../hooks/rate-limit'
import RegionsAutocompleteSuggestion from './regions-autocomplete-suggestion'
import { useTranslation } from '../../../../../hooks/locale'
import RegionsAutocompleteSuggestionTransport from '../../../../../transports/regions-autocomplete-suggestion-transport'
import { LocationPickerPassThroughProps } from '../../../../common/location-picker/types'
import { SearchbarLocationPickerProps } from '../location-picker/types'

type Props = LocationPickerPassThroughProps &
    Pick<
        SearchbarLocationPickerProps<RegionsAutocompleteSuggestionTransport>,
        'onLocationInputChange' | 'onLocationItemSelect' | 'onHistoryItemSelect'
    >

const _getSuggestionKey = (item: RegionsAutocompleteSuggestionTransport) => item.regionId

const RegionsAutocompleteSuggestionPicker: FC<Props> = props => {
    const { t } = useTranslation()

    const { regionsAutocompleteSuggestions, getRegionsAutocompleteSuggestions, setRegionsAutocompleteSuggestions } =
        useRegionsAutocompleteSuggestions()

    const getDebouncedRegionsAutocompleteSuggestions = useDebounce(getRegionsAutocompleteSuggestions, 300)

    const { onKeyUp } = useTracking()

    return (
        <SearchbarLocationPicker
            {...props}
            locationItems={regionsAutocompleteSuggestions}
            getLocationItems={getDebouncedRegionsAutocompleteSuggestions}
            setLocationItems={setRegionsAutocompleteSuggestions}
            locationItemComponent={RegionsAutocompleteSuggestion}
            locationInputPlaceholder={t('whereTo')}
            getLocationItemKey={_getSuggestionKey}
            onKeyUp={onKeyUp}
        />
    )
}

export default memo(RegionsAutocompleteSuggestionPicker)
