import React, { FC, memo } from 'react'

import { FeatureTransport } from '../../../transports/common/listing-transport'
import { getFeatureTextFromKey } from '../../../utils/miscellaneous-utils'
import { ExtraFeature, Feature, FeatureList, FeatureText, FeatureTooltip } from './styles'
import { useTranslation } from '../../../hooks/locale'
import FeatureIcon, { getFeatureIconComponent } from './feature-icon'
import { SITE_NAME } from '../../../common/constants'

interface Props {
    features: { [key: string]: FeatureTransport }
    siteName?: SITE_NAME
}

const MAX_FEATURES_LENGTH = 3

const Features: FC<Props> = ({ features, siteName }) => {
    const { t } = useTranslation()
    let indexFeatures = 0
    let shownFeatures = 0
    return (
        <FeatureList siteName={siteName}>
            {features &&
                Object.keys(features).map((key, index) => {
                    indexFeatures += getFeatureIconComponent(key) ? 1 : 0
                    shownFeatures =
                        indexFeatures <= MAX_FEATURES_LENGTH && !!getFeatureIconComponent(key) ? index : shownFeatures

                    return (
                        <Feature
                            key={key}
                            visible={indexFeatures <= MAX_FEATURES_LENGTH && !!getFeatureIconComponent(key)}
                        >
                            <FeatureIcon featureKey={key} />
                            <FeatureText>{`${getFeatureTextFromKey(key, t)}`}</FeatureText>
                        </Feature>
                    )
                })}
            <Feature visible>
                <FeatureTooltip>
                    {Object.keys(features).map((key, index) => {
                        if (index > shownFeatures) {
                            return <ExtraFeature key={`${key}_${index}`}>{features[key].tooltip}</ExtraFeature>
                        }
                    })}
                </FeatureTooltip>
                <FeatureText>{`${
                    indexFeatures > MAX_FEATURES_LENGTH ? `+${indexFeatures - MAX_FEATURES_LENGTH} ${t('more')}` : ''
                }`}</FeatureText>
            </Feature>
        </FeatureList>
    )
}

export default memo(Features)
