import { RequestUserConfig } from '../common/types'
import { SearchUrlRequestBodyTransport } from '../transports/search-url-transport'
import { searchClient } from '../clients'
import { useRequestUserConfigContext } from './request'
import { useAsyncCallback } from './async'
import { useCallback } from 'react'

const _getSearchUrl = async (user: RequestUserConfig, filter: string, params: SearchUrlRequestBodyTransport) => {
    const response = await searchClient.getSearchUrl({
        user,
        filter,
        params,
    })

    return response.data.searchUrl
}

export const useSearchUrl = () => {
    const user = useRequestUserConfigContext()

    const { data: searchUrl, isLoading, error, callback } = useAsyncCallback<string>(_getSearchUrl)

    const getSearchUrl = useCallback(
        (filter: string, params: SearchUrlRequestBodyTransport) => callback(user, filter, params),
        [callback, user],
    )

    return {
        searchUrl,
        isLoading,
        error,
        getSearchUrl,
    }
}
