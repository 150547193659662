import { AnyStyledComponent } from 'styled-components'
import React, { FC, useCallback, useState, MouseEvent, useEffect } from 'react'

import CookieManagerService from '../../../services/cookie-manager-service'
import { Container, WishlistIcon } from './styles'
import { getUserDetailsModel } from '../../../redux/user/selectors'
import { useDispatch, useSelector } from 'react-redux'
import listingClient from '../../../clients/listing-client'
import GreyHeartIcon from '../../../assets/icons/object-icons/heart-grey.svg'
import PinkHeartIcon from '../../../assets/icons/object-icons/heart-pink.svg'
import { useRequestUserConfigContext } from '../../../hooks/request'
import { decrementCountTransport, incrementCountTransport } from '../../../redux/wishlist/actions'

const _addListingIdToCookie = (listingId: string) => {
    CookieManagerService.appendValueInCookieList(CookieManagerService.FAVORITE_LISTINGS_IDS, listingId)
}

const _removeListingIdFromCookie = (listingId: string) => {
    CookieManagerService.removeValueFromCookieList(CookieManagerService.FAVORITE_LISTINGS_IDS, listingId)
}

interface Props {
    containerBackgroundColor?: string
    containerBorderRadius?: string
    containerWidth?: string
    containerHeight?: string
    containerMargin?: string
    containerPadding?: string
    width?: string
    height?: string
    listingId: string
}

const WishlistSection: FC<Props> = ({
    containerBackgroundColor,
    containerBorderRadius,
    containerHeight,
    containerWidth,
    containerMargin,
    containerPadding,
    width,
    height,
    listingId,
}) => {
    const [isWishlist, setIsWishlist] = useState(false)
    const userDetails = useSelector(getUserDetailsModel)
    const user = useRequestUserConfigContext()
    const dispatch = useDispatch()

    const handleWishlistChange = useCallback(
        async (event: MouseEvent | TouchEvent) => {
            event.preventDefault()
            event.stopPropagation()
            if (!!userDetails) {
                await (isWishlist
                    ? listingClient.removeFromWishlist(user, listingId)
                    : listingClient.addToWishlist(user, listingId))
            } else {
                isWishlist ? _removeListingIdFromCookie(listingId) : _addListingIdToCookie(listingId)
            }
            setIsWishlist(!isWishlist)
            isWishlist ? dispatch(decrementCountTransport()) : dispatch(incrementCountTransport())
        },
        [user, isWishlist, listingId, userDetails],
    )

    useEffect(() => {
        if (userDetails && userDetails.favoriteListings) {
            const isFavorite = userDetails.favoriteListings.includes(listingId)

            isFavorite ? setIsWishlist(true) : setIsWishlist(false)
        } else {
            if (CookieManagerService.getCookie(CookieManagerService.FAVORITE_LISTINGS_IDS)) {
                const isFavorite = CookieManagerService.isValueAppendedInCookieList(
                    CookieManagerService.FAVORITE_LISTINGS_IDS,
                    listingId,
                )

                isFavorite ? setIsWishlist(true) : setIsWishlist(false)
            }
        }
    }, [listingId, userDetails])

    return (
        <Container
            onClick={handleWishlistChange}
            containerBackgroundColor={containerBackgroundColor}
            containerBorderRadius={containerBorderRadius}
            containerHeight={containerHeight}
            containerWidth={containerWidth}
            containerMargin={containerMargin}
            containerPadding={containerPadding}
        >
            {isWishlist ? (
                <WishlistIcon
                    as={PinkHeartIcon as AnyStyledComponent}
                    alt="wishlist icon"
                    width={width}
                    height={height}
                />
            ) : (
                <WishlistIcon
                    as={GreyHeartIcon as AnyStyledComponent}
                    alt="wishlist icon"
                    width={width}
                    height={height}
                />
            )}
        </Container>
    )
}

export default WishlistSection
