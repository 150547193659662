import React, { FC, useCallback } from 'react'

import RegionsAutocompleteSuggestionTransport from '../../../../../transports/regions-autocomplete-suggestion-transport'
import { Pill, SuggestionItemTitle } from './regions-autocomplete-suggestion-styles'
import { LocationPinIcon } from '../../../../common/icons'
import { ItemContainer } from '../../../../common/location-picker/location-picker-items-styles'
import { useTranslation } from '../../../../../hooks/locale'
import { LocationItemComponentProps } from '../../../../common/location-picker/types'

const RegionsAutocompleteSuggestion: FC<LocationItemComponentProps<RegionsAutocompleteSuggestionTransport>> = ({
    item,
    onSelect,
    isFocused,
    onFocus,
}) => {
    const { count, title } = item
    const { t } = useTranslation()

    const _handleMouseEnter = useCallback(() => onFocus(item), [onFocus, item])
    const _handleMouseLeave = useCallback(() => onFocus(null), [onFocus])

    const _handleSelect = useCallback(() => onSelect(item), [item, onSelect])

    return (
        <ItemContainer
            onClick={_handleSelect}
            isFocused={isFocused}
            onMouseEnter={_handleMouseEnter}
            onMouseLeave={_handleMouseLeave}
        >
            <LocationPinIcon alt={t('mapIconText')} />
            <SuggestionItemTitle>{title}</SuggestionItemTitle>
            <Pill>
                {count} {t('accommodations')}
            </Pill>
        </ItemContainer>
    )
}

export default RegionsAutocompleteSuggestion
