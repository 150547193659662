export const commonPageEvents = {
    searchBarSection: {
        CLICK_PERS_FILTER: 'clickPersFilter',
        SENT_PERS_FILTER: 'sentPersFilter',
    },
    keyBoardEvents: {
        KEY_UP_EVENT: 'keyupEvent',
    },
}

export const searchResultListingsPageEvents = {
    listViewPageSpecific: {
        TO_BE_ADDED: 'to_be_added',
    },
    mapViewPageSpecific: {
        TO_BE_ADDED: 'to_be_added',
    },
    tileViewPageSpecific: {
        TO_BE_ADDED: 'to_be_added',
    },
    filterSection: {
        CLICK_RESULT_FILTER: 'clickResultFilter',
    },
}
