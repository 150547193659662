import { desktopBreakpoint, tabletBreakpoint } from 'react-components'
import styled, { css } from 'styled-components'

import { SITE_NAME } from '../../../common/constants'

export const FeatureList = styled.div<{ siteName?: SITE_NAME }>(
    ({ theme, siteName }) => css`
        font-size: ${theme.typography.font.size.small};
        line-height: 1.5rem;
        display: ${theme.style.display.flex};
        color: ${theme.colors.tfwBlack87};
        margin-top: ${theme.spacing.xSmall};
        width: max-content;
        @media only screen and (min-width: ${tabletBreakpoint.min}px) {
            ${siteName === SITE_NAME.SRL_PAGE_MAP_VIEW &&
            css`
                margin: 0;
            `};
        }
        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            margin-top: ${theme.spacing.small};
        }
    `,
)
export const FeatureTooltip = styled.div(
    ({ theme }) => css`
        padding: 0.375rem ${theme.spacing.medium};
        display: flex;
        flex-direction: column;
        opacity: 0;
        position: absolute;
        left: 90%;
        bottom: -70%;
        z-index: 10;
        background-color: ${theme.colors.white};
        color: ${theme.colors.tfwBlack87};
        transition: opacity 200ms ease-in;
        width: max-content;

        ::after {
            /* for the arrow */
            content: '';
            display: block;
            position: absolute;
            top: 80%;
            right: 94%; /* To the left of the tooltip */
            margin-top: -10%;
            border-width: ${theme.typography.border.width.thick};
            border-style: solid;
            border-color: white;
            border-radius: 0.1875rem;
            box-shadow: -1px 1px ${theme.colors.tfwBlackOpacity10};
            transform: rotate(45deg);
        }

        box-sizing: border-box;
        border: ${theme.typography.border.width.xxxThin} ${theme.typography.border.style.solid}
            ${theme.colors.tfwBlackOpacity10};
        border-radius: ${theme.typography.border.radius.medium};

        @media only screen and (min-width: ${tabletBreakpoint.min}px) and (max-width: ${tabletBreakpoint.max}px) {
            display: none;
        }
    `,
)

interface FeatureProps {
    visible: boolean
}

export const Feature = styled.div<FeatureProps>`
    display: ${props => (props.visible ? 'flex' : 'none')};
    align-items: center;
    position: relative;

    &:hover ${FeatureTooltip} {
        opacity: 1;
    }
`

export const FeatureText = styled.span(
    ({ theme }) => css`
        position: relative;
        font-weight: ${theme.typography.font.weight.normal};
        margin-right: ${theme.spacing.medium};
        @media only screen and (min-width: ${desktopBreakpoint.min}px) {
            margin-right: ${theme.spacing.large};
        }
    `,
)

export const ExtraFeature = styled.div(
    ({ theme }) => css`
        padding: ${theme.spacing.xxSmall} 0;
        font-family: ${theme.typography.font.family.lato};
        font-size: ${theme.typography.font.size.small};
        line-height: 1.5rem;
    `,
)

export const FeatureIconImage = styled.svg`
    height: 1rem;
    width: 1rem;
    margin-right: ${props => props.theme.spacing.xSmall};
`
